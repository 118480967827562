<template>
  <div>
    <div
      :class="isMobile ? 'sidebarGroup' : 'paymentAcceptcontainer'"
      v-if="paymentServiceLogoLoaded && activeLogoList.length"
    >
      <q-list dense class="row items-center">
        <q-item-label
          class="col-12 title"
          :class="isMobile ? '' : 'col-md-auto'"
          >We accept</q-item-label
        >
        <q-item
          class="col-12 paymentAcceptIcons"
          :class="isMobile ? '' : 'col-md-auto'"
        >
          <template v-for="paymentService of activeLogoList">
            <img
              v-if="paymentService.isActive"
              :key="paymentService.id"
              :src="`images/payment/${paymentService.paymentLogoName}.webp`"
              :title="paymentService.paymentTitle"
              class="q-mr-xs"
            />
          </template>
        </q-item>
      </q-list>
    </div>
    <template v-else-if="!paymentServiceLogoLoaded">
      <slot name="skeleton"></slot>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SectionWeAccept',
  mounted() {
    if (!this.paymentServiceLogoLoaded)
      this.$store.dispatch('common/getPaymentServiceLogos').finally(() => {
        this.$store.commit('common/SET_PAYMENT_SERVICES_LOGO_LOADED', true)
      })
  },
  computed: {
    ...mapGetters('common', [
      'paymentServiceLogoLoaded',
      'paymentServiceLogos',
    ]),
    activeLogoList() {
      return this.paymentServiceLogos.filter((item) => item.isActive)
    },
  },
}
</script>

<style lang="scss">
.paymentAcceptIcons {
  img {
    display: inline-block;
    max-width: 48px;
    height: 32px;
    @media (max-width: 767px) {
      max-width: 46px;
      height: 30px;
    }
    @media (max-width: 374px) {
      max-width: 36px;
      height: 24px;
    }
  }
}
.paymentAcceptcontainer {
  display: inline-flex;
  justify-content: flex-end;
}
</style>
